import React from 'react'
import {
  Table,
  Space,
  Tooltip,
} from 'antd';

const SftpImportConfigTable = props => {
  const { 
    sftp_import_configs,
    // can_read_users,
    // can_read_grantings,
  } = props

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (sftp_import_config) => (
        <Tooltip title={sftp_import_config.active ? 'Enabled' : 'Disabled' }>
          {sftp_import_config.active ? (
            <i className="green checkmark icon" />
          ) : (
            <i className="text-muted ban icon" />
          )}
          <span>{sftp_import_config.name}</span>
        </Tooltip>
      )
    },
    {
      title: 'Core Data Steward',
      key: 'coreDataSteward',
      render: (sftp_import_config) => sftp_import_config.core_data_steward.fullname
    },
    {
      title: "Config SFTP",
      key: 'sftpConfig',
      render: (sftp_import_config) => (
        <>
          <div>
            <span>Server: </span>
            <span><b>{sftp_import_config.ftp_server}</b></span>
          </div>
          <div>
            <span>User: </span>
            <span><b>{sftp_import_config.ftp_user}</b></span>
          </div>
        </>
      )
    },
    {
      title: 'File',
      key: 'file',
      render: (sftp_import_config) => (
        <>
          <div>
            {sftp_import_config.remote_path || '/'}
          </div>
          <div>
            {sftp_import_config.filename}
          </div>
        </>
      )
    },
    {
      title: 'Scheduling',
      key: 'scheduling',
      dataIndex: 'display_schedule', 
    },
    {
      title: 'Last status',
      key: 'lastStatus',
      render: (sftp_import_config) => {
        if(!sftp_import_config.last_status) return '-'
        return (
          <Tooltip title={sftp_import_config.last_log}>
            {logoForStatus(sftp_import_config.last_status)}
            <span>
              {sftp_import_config.display_last_import_at}
            </span>
          </Tooltip>
        )
      }
    },
    {
      title: 'Notifications',
      key: 'notifications',
      render: (sftp_import_config) => (
        <>
          <div>
            {sftp_import_config.notification_emails.map((email) => (email)).join(', ')}
          </div>
          {sftp_import_config.notify_if_success && (
            <div>
              <i className="green checkmark icon" />
              <span>Notify if success</span>
            </div>
          )}
        </>
      )
    }
  ]

  const logoForStatus = (status) => {
    switch(status) {
      case 'success':
        return <i className="green checkmark icon" />
      case 'warning':
        return <i className="orange exclamation circle icon" />
      case 'error':
        return <i className="red times icon" />
    }
  }

  return (
    <>
      <Table
        className="vertical-align-top"
        dataSource={sftp_import_configs}
        columns={columns}
        rowKey="id"
        pagination={false}
        size="middle"
        bordered
        sticky
      />
    </>
  )
}

export default SftpImportConfigTable;